/* @todo: move to reset file once all button references have been swapped */

.Button_buttonReset__Bkof2 {
  /* vertically centers button content; prevents misalignment (particularly of icons) caused by character descenders */
  display: block;
  width: -moz-fit-content;
  width: fit-content;

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.Button_linkReset__0Jlvi {
  /* vertically centers button content; prevents misalignment (particularly of icons) caused by character descenders */
  display: block;
  width: -moz-fit-content;
  width: fit-content;

  font-size: 16px;
}

/* base class for styled buttons */
.Button_baseStyling__73DD6 {
  font-weight: 500;
}

.Button_button__xuGlT:active {
  filter: brightness(80%);
}

.Button_button__xuGlT:disabled {
  opacity: 0.5;
}

.Button_inline__soFLY {
  color: var(--foremost-blue);
  text-decoration: underline;
}

.Button_inline__soFLY.Button_danger__BGvjM {
  color: var(--foremost-danger);
}

.Button_inlineGraySmall__86Tto {
  color: var(--foremost-gray);
  font-size: 12px;
  text-decoration: underline;
}

.Button_ghostBlue__iY6nt,
.Button_ghostBlueSmall__32IJB,
.Button_ghostOrange__bXSWg,
.Button_ghostOrangeSmall__JmYIA {
  border-radius: 500px;
  border-style: solid;
  border-width: 2px;
  text-align: center;
}

.Button_ghostBlue__iY6nt, .Button_ghostOrange__bXSWg {
  padding: 13px 23px;
  width: 200px;
}

.Button_ghostBlueSmall__32IJB, .Button_ghostOrangeSmall__JmYIA {
  font-size: 12px;
  padding: 6px 13px;
  width: 100px;
}

.Button_ghostBlue__iY6nt, .Button_ghostBlueSmall__32IJB {
  border-color: var(--foremost-blue);
  color: var(--foremost-blue);
}

.Button_ghostOrange__bXSWg, .Button_ghostOrangeSmall__JmYIA {
  border-color: var(--foremost-orange);
  color: var(--foremost-orange);
}

.Button_ghostBlue__iY6nt.Button_danger__BGvjM,
.Button_ghostBlueSmall__32IJB.Button_danger__BGvjM,
.Button_ghostOrange__bXSWg.Button_danger__BGvjM,
.Button_ghostOrangeSmall__JmYIA.Button_danger__BGvjM {
  border-color: var(--foremost-danger);
  color: var(--foremost-danger);
}

.Button_fillBlue__uxl7R,
.Button_fillBlueSmall__KOnaX,
.Button_fillOrange__mUFYs,
.Button_fillOrangeSmall__UHcRy {
  border-radius: 500px;
  text-align: center;
}

.Button_fillBlue__uxl7R, .Button_fillOrange__mUFYs {
  padding: 15px 25px;
  width: 200px;
}

.Button_fillBlueSmall__KOnaX, .Button_fillOrangeSmall__UHcRy {
  font-size: 12px;
  padding: 8px 15px;
  width: 100px;
}

.Button_fillBlue__uxl7R, .Button_fillBlueSmall__KOnaX {
  background-color: var(--foremost-blue);
  color: #FFF;
}

.Button_fillOrange__mUFYs, .Button_fillOrangeSmall__UHcRy {
  background-color: var(--foremost-orange);
  color: #FFF;
}

.Button_fillBlue__uxl7R.Button_danger__BGvjM,
.Button_fillBlueSmall__KOnaX.Button_danger__BGvjM,
.Button_fillOrange__mUFYs.Button_danger__BGvjM,
.Button_fillOrangeSmall__UHcRy.Button_danger__BGvjM {
  background-color: var(--foremost-danger);
}

.Button_ghostBlue__iY6nt:active,
.Button_ghostBlueSmall__32IJB:active,
.Button_ghostOrange__bXSWg:active,
.Button_ghostOrangeSmall__JmYIA:active,
.Button_fillBlue__uxl7R:active,
.Button_fillBlueSmall__KOnaX:active,
.Button_fillOrange__mUFYs:active,
.Button_fillOrangeSmall__UHcRy:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.Button_ghostBlue__iY6nt.Button_contentWidth__ANOyl,
.Button_ghostBlueSmall__32IJB.Button_contentWidth__ANOyl,
.Button_ghostOrange__bXSWg.Button_contentWidth__ANOyl,
.Button_ghostOrangeSmall__JmYIA.Button_contentWidth__ANOyl,
.Button_fillBlue__uxl7R.Button_contentWidth__ANOyl,
.Button_fillBlueSmall__KOnaX.Button_contentWidth__ANOyl,
.Button_fillOrange__mUFYs.Button_contentWidth__ANOyl,
.Button_fillOrangeSmall__UHcRy.Button_contentWidth__ANOyl {
  width: -moz-fit-content;
  width: fit-content;
}

.Button_plain__0_FXl {
  color: var(--foremost-white);
}

@media screen and (max-width: 768px) {
  .Button_inlineGraySmall__86Tto {
    font-size: 11px;
  }

  .Button_ghostBlue__iY6nt, .Button_ghostOrange__bXSWg {
    font-size: 15px;
    padding: 10px 18px;
    width: 180px;
  }

  .Button_ghostBlueSmall__32IJB, .Button_ghostOrangeSmall__JmYIA {
    font-size: 11px;
    padding: 5px 11px;
    width: 91px;
  }

  .Button_fillBlue__uxl7R, .Button_fillOrange__mUFYs {
    font-size: 15px;
    padding: 12px 20px;
    width: 180px;
  }

  .Button_fillBlueSmall__KOnaX, .Button_fillOrangeSmall__UHcRy {
    font-size: 11px;
    padding: 7px 13px;
    width: 91px;
  }
}

.Modal_background___TuqP {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.Modal_background___TuqP.Modal_stackLevel110__UQKkR {
  z-index: 110;
}

.Modal_background___TuqP.Modal_stackLevel120__Mg68J {
  z-index: 120;
}

.Modal_background___TuqP.Modal_stackLevel130__zRDw6 {
  z-index: 130;
}

.Modal_flexColumn__f0Pv1 {
  display: flex;
  flex-direction: column;
}

.Modal_modal__e89cA {
  background-color: #FFF;
  border-radius: 10px;
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
  padding: 25px;
}

.Modal_modal__e89cA.Modal_medium__bEAAW {
  min-height: 60vh;
  min-width: 60vw;
}

@media screen and (max-width: 768px) {
  .Modal_modal__e89cA.Modal_medium__bEAAW {
    min-height: 80vh;
    min-width: 80vw;
  }
}

.Actions_actions__zDayg {
  align-items: center;
  column-gap: 50px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.Actions_container__BZ6AU {
  max-width: 600px;
}

@media screen and (max-width: 768px) {
  .Actions_actions__zDayg {
    column-gap: 40px;
  }
}

.Alert_text__EPlQL {
  font-size: 18px;
  line-height: 1.3;
}

.Confirmation_text__5Bua0 {
  font-size: 20px;
  /* reset font-weight, in case of inheritance */
  font-weight: initial;
  line-height: 1.3;
}

.Loader_background__lhiMx {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.Loader_content__7GzHL {
  color: #FFF;
  font-size: 32px;
  font-weight: 500;
}

.styles_buttonPanel__fkxwW {
  display: flex;
  column-gap: 15px;
  margin-top: 10px;
}

.styles_errorText__N5FZ4 {
  color: var(--error-red);
}

.styles_fieldSpacing___J993:not(:last-child) {
  margin-bottom: var(--input-field-spacing);
}

.styles_fileInput__yELiV {
  /*
    Hide text that browser places next to file button ("No file chosen", name of file, etc).
    This is to prevent confusion when an upload is aborted (due to invalid file type), where the
    user may believe that displaying the file name indicates the file was uploaded (when it was not).
  */
  color: transparent;
}

.styles_groupFields__HHLpt.styles_readonlyColumns__Avxx6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  column-gap: 30px;
}

.styles_groupFields__HHLpt.styles_readonlyColumns__Avxx6.styles_columns3__PWxVG {
  grid-template-columns: 1fr 1fr 1fr;
}

.styles_groupHeader__89R1N {
  display: block;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  text-decoration: underline;
}

.styles_groupHeaderSticky__EvTuX {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: var(--foremost-blue-light);
  border-bottom: 1px solid var(--foremost-blue-pale);
  border-top: 1px solid var(--foremost-blue-pale);
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-left: -30px;
  margin-right: -30px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.3);
}

/* adding margin spacing to top AND bottom of groups (rather than bottom-only) to give spacing between groups and non-grouped fields */
.styles_groupWrapper__mQcTW.styles_groupSpacing__0Z9AL:not(:first-child) {
  margin-top: 40px;
}

.styles_groupWrapper__mQcTW.styles_groupSpacing__0Z9AL:not(:last-child) {
  margin-bottom: 40px;
}

.styles_groupWrapper__mQcTW.styles_groupWrapperFrame__fwHXO {
  background: var(--foremost-blue-faint-1);
  border: 1px solid var(--foremost-blue-faint-2);
  border-radius: 8px;
  padding: 20px;
}

.styles_hiddenInput__GipV5 {
  display: none;
}

.styles_image__STr01 {
  margin-bottom: 20px;
  max-width: 100%;
}

.styles_input__IZevv {
  font-size: 14px;
  line-height: 1.4;
  padding: 9px 15px;
  border-radius: 8px;
  border: 1px solid var(--foremost-gray);
  background-color: #fff;
}

.styles_input__IZevv:focus {
  outline: none;
  border-color: var(--emperor);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.styles_input__IZevv.styles_date__fmP47 {
  cursor: pointer;
}

.styles_input__IZevv.styles_dateTime__DyXqG {
  cursor: pointer;
}

.styles_input__IZevv.styles_dropdown__7j_H5 {
  cursor: pointer;
  /* max-width prevents possible overflow if options cause dropdown to be excessively wide */
  max-width: 100%;

  /* setting up custom dropdown arrow; dropdown arrow uses "emperor" color */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1 7 7 1 1' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 65px;
}

.styles_input__IZevv.styles_textAlphabetical__xbKKg {
  width: 100%;
}

.styles_input__IZevv.styles_textarea__6ZTOP {
  height: 100px;
  width: 100%;
}

.styles_label__mtMlT {
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 8px;
}

.styles_label__mtMlT.styles_readonlyLabel__jywzV {
  text-decoration: underline;
}

.styles_lateralFields__Qexp3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 5px;
}

.styles_optionGroup__iME_L {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 50px;
  gap: 12px 50px;
  align-items: center;
}

.styles_repeatableGroups__Vei1l {
  display: flex;
  flex-direction: column;
  row-gap: var(--input-field-spacing);
}

.styles_repeatableGroupsSpacing__0BKqf:not(:last-child) {
  margin-bottom: var(--input-field-spacing);
}

.styles_subLabel__iAYq6 {
  font-size: 12px;
  margin-bottom: 12px;
}

.styles_upload__Atj_t {
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: space-between;
}

.styles_uploadIndicator__FxwIc {
  color: var(--foremost-blue);
  margin-top: 10px;
}

.styles_uploadsWrapper__h7gzp {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .styles_input__IZevv.styles_search___bj_t {
    max-width: 140px;
  }

  .styles_optionGroup__iME_L {
    column-gap: 30px;
  }
}

@media print {
  .styles_label__mtMlT {
    font-size: 11px;
    padding-bottom: 5px;
  }

  .styles_readonlyValue__WZvOG {
    font-size: 13px;
  }
}

.DangerConfirm_paragraph__FtJiy {
  margin: 0 0 30px;
}

/* @todo: move to reset file once all button references have been swapped */
.option_buttonReset__ciQxq {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.option_option__I7DNE {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px var(--foremost-gray);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
  color: var(--gray-dark);
  font-size: 14px;
  padding: 10px 20px;
}

.option_option__I7DNE:active {
  filter: brightness(98%);
}

.option_option__I7DNE.option_selected__teqJ2 {
  background-color: var(--foremost-white);
  border-color: var(--foremost-blue);
  color: var(--foremost-blue);
}

.SignatureBox_buttons__Vr0s5 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.SignatureBox_canvas__po_8v {
  display: block;

  /* avoid single-finger scroll on touchscreen */
  touch-action: none;

  /* avoid element highlight from long press on touchscreen */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  /* width and height are designed to fill out container */
  width: 100%;
  height: 100%;
}

.SignatureBox_canvasWrapper__UTmAJ {
  aspect-ratio: 3 / 1;
  border: 1px solid black;
}

.Form_defaultButtons__x9ZaM {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 40px;
}

.Form_description__McXed {
  color: var(--foremost-gray);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

.Form_fixedActionsWrapper__HcNQH {
  background-color: rgb(248, 248, 248);
  box-shadow: 0 -4px 4px 0 var(--foremost-black-faint-3);
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
}

.Form_fixedButtons__AVE0S {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  padding: 25px 20px;
}

.Form_form__LS88q {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.Form_form__LS88q.Form_withBackground__4MmOd {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 var(--foremost-black-faint-4);
  margin-bottom: 150px;
  padding: 30px;
}

.Form_inlineActions__iCBqr {
  display: flex;
  align-items: center;
  column-gap: 50px;
  justify-content: center;
  margin-top: 40px;
}

.Form_line__UiROa {
  background-color: var(--foremost-blue-pale);
  border: none;
  height: 1px;
  margin: 0;
}

.Form_navigator__a3YWo {
  display: flex;
  overflow-x: auto;
}

.Form_navigatorButton__tKFoT {
  background-color: var(--foremost-blue-light);
  color: var(--foremost-blue-dark);
  cursor: pointer;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.Form_navigatorButton__tKFoT:active {
  filter: brightness(80%);
}

.Form_navigatorButton__tKFoT:not(:last-child) {
  border-right: 1px solid var(--foremost-blue-pale);
}

.Form_pageCount__Mgo_f {
  color: var(--foremost-gray);
  font-size: 12px;
  margin-bottom: 10px;
}

.Form_pageMarker__fR_nt {
  background-color: var(--foremost-gray-faint);
  flex-grow: 1;
  height: 4px;
}

.Form_pageMarker__fR_nt.Form_pageMarkerMarked__drLTM {
  background-color: var(--foremost-blue);
}

.Form_pageTracker__Fv8F_ {
  column-gap: 4px;
  display: flex;
  margin-bottom: 10px;
}

@media print {
  .Form_form__LS88q, .Form_form__LS88q.Form_withBackground__4MmOd {
    border-radius: 0;
    box-shadow: none;
    column-count: 2;
    column-fill: balance;
    column-gap: 50px;
    column-rule: 1px solid var(--foremost-gray-faint);
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

input,
button,
select,
textarea {
  color: inherit;
  font-family: inherit;
}

img, svg {
  display: block;
}

:root {
  /* Colors */
  --emperor: #555;
  --error-red: #F00;
  --foremost-black: #212129;
  --foremost-black-faint-2: rgba(33, 33, 41, 0.1);
  --foremost-black-faint-3: rgba(33, 33, 41, 0.15);
  --foremost-black-faint-4: rgba(33, 33, 41, 0.2);
  --foremost-black-faint-6: rgba(33, 33, 41, 0.3);
  --foremost-blue: #05A5E1;
  --foremost-blue-dark: #2D6382;
  --foremost-blue-faint-1: rgba(5, 165, 225, 0.05);
  --foremost-blue-faint-2: rgba(5, 165, 225, 0.1);
  --foremost-blue-faint-3: rgba(5, 165, 225, 0.15);
  --foremost-blue-faint-6: rgba(5, 165, 225, 0.3);
  --foremost-blue-light: #E4F2FA;
  --foremost-blue-pale: #BDDDF2;
  --foremost-danger: #B33A3A;
  --foremost-gray: #ACB3CB;
  --foremost-gray-dim: #ACB3CB77;
  --foremost-gray-faint: #EAEAEA;
  --foremost-green: #A8D863;
  --foremost-orange: #EFBA53;
  --foremost-red: #EA766E;
  --foremost-white: #EDF4FA;
  --gray: #888;
  --gray-dark: #66666B;

  /* Sizes */
  --input-field-spacing: 20px;
}

body {
  color: var(--foremost-black);
}

.detailsBlock {
  border-top: 1px solid var(--foremost-gray-dim);
  margin-bottom: 60px;
  padding-top: 50px;
}

.detailsGrid {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
}

.detailsGrid.columns2 {
  grid-template-columns: 1fr 2fr;
}

.detailsGrid .detailLabel {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-decoration: underline;
}

.detailsGrid .detailValue {
  font-size: 20px;
  font-weight: 500;
}

.detailsGrid .gridCellLink {
  font-size: 16px; /* @todo Remove after updating Button component */
  margin-top: 24px;
}

.fixedActions {
  background-color: rgb(248, 248, 248);
  box-shadow: 0 -4px 4px 0 var(--foremost-black-faint-3);
  padding: 25px 20px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;

  position: fixed;
  bottom: 0;
  left: 0;
}

.heading1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.heading2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1em;
}

.headingName {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 1em;
}

.listTable {
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  width: 100%;
}

.listTable .buttonPanel {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  align-items: center;
}

.listTable .multiItemCell {
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.listTable .multiItemVertCell {
  line-height: 1.7;
}

.listTable td,
.listTable th {
  border-bottom: 1px solid #acb3cb;
  padding: 20px 0;
  cursor: default;
}

.listTable td:not(:last-child),
.listTable th:not(:last-child) {
  padding-right: 15px;
}

.listTable th {
  font-size: 14px;
  font-weight: 600;
}

/* Altering styling of FullCalendar's intrinsic elements.
When applying "customDateHeader" class above FullCalendar instance,
this expands the date header width fully to enable adding elements. */
.customDateHeader .fc .fc-daygrid-day-number {
  width: 100%;
}

.noEventHover .fc-daygrid-dot-event:hover {
  background: unset;
}

.smallHeading .fc .fc-toolbar-title {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .detailsGrid {
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
  }

  .largeScreenOnly {
    display: none;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/55c55f0601d81cf3.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/26a46d62cd723877.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/97e0cb1ae144a2a9.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/581909926a08bbc8.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/df0a9ae256c0569c.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/6d93bde91c0c2823.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_dcf180';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/a34f9d1faa5f3315.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_dcf180';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_dcf180 {font-family: '__Inter_dcf180', '__Inter_Fallback_dcf180';font-style: normal
}

