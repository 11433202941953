.defaultButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 40px;
}

.description {
  color: var(--foremost-gray);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

.fixedActionsWrapper {
  background-color: rgb(248, 248, 248);
  box-shadow: 0 -4px 4px 0 var(--foremost-black-faint-3);
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
}

.fixedButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  padding: 25px 20px;
}

.form {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.form.withBackground {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 var(--foremost-black-faint-4);
  margin-bottom: 150px;
  padding: 30px;
}

.inlineActions {
  display: flex;
  align-items: center;
  column-gap: 50px;
  justify-content: center;
  margin-top: 40px;
}

.line {
  background-color: var(--foremost-blue-pale);
  border: none;
  height: 1px;
  margin: 0;
}

.navigator {
  display: flex;
  overflow-x: auto;
}

.navigatorButton {
  background-color: var(--foremost-blue-light);
  color: var(--foremost-blue-dark);
  cursor: pointer;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.navigatorButton:active {
  filter: brightness(80%);
}

.navigatorButton:not(:last-child) {
  border-right: 1px solid var(--foremost-blue-pale);
}

.pageCount {
  color: var(--foremost-gray);
  font-size: 12px;
  margin-bottom: 10px;
}

.pageMarker {
  background-color: var(--foremost-gray-faint);
  flex-grow: 1;
  height: 4px;
}

.pageMarker.pageMarkerMarked {
  background-color: var(--foremost-blue);
}

.pageTracker {
  column-gap: 4px;
  display: flex;
  margin-bottom: 10px;
}

@media print {
  .form, .form.withBackground {
    border-radius: 0;
    box-shadow: none;
    column-count: 2;
    column-fill: balance;
    column-gap: 50px;
    column-rule: 1px solid var(--foremost-gray-faint);
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
}
