:root {
  /* Colors */
  --emperor: #555;
  --error-red: #F00;
  --foremost-black: #212129;
  --foremost-black-faint-2: rgba(33, 33, 41, 0.1);
  --foremost-black-faint-3: rgba(33, 33, 41, 0.15);
  --foremost-black-faint-4: rgba(33, 33, 41, 0.2);
  --foremost-black-faint-6: rgba(33, 33, 41, 0.3);
  --foremost-blue: #05A5E1;
  --foremost-blue-dark: #2D6382;
  --foremost-blue-faint-1: rgba(5, 165, 225, 0.05);
  --foremost-blue-faint-2: rgba(5, 165, 225, 0.1);
  --foremost-blue-faint-3: rgba(5, 165, 225, 0.15);
  --foremost-blue-faint-6: rgba(5, 165, 225, 0.3);
  --foremost-blue-light: #E4F2FA;
  --foremost-blue-pale: #BDDDF2;
  --foremost-danger: #B33A3A;
  --foremost-gray: #ACB3CB;
  --foremost-gray-dim: #ACB3CB77;
  --foremost-gray-faint: #EAEAEA;
  --foremost-green: #A8D863;
  --foremost-orange: #EFBA53;
  --foremost-red: #EA766E;
  --foremost-white: #EDF4FA;
  --gray: #888;
  --gray-dark: #66666B;

  /* Sizes */
  --input-field-spacing: 20px;
}

body {
  color: var(--foremost-black);
}

.detailsBlock {
  border-top: 1px solid var(--foremost-gray-dim);
  margin-bottom: 60px;
  padding-top: 50px;
}

.detailsGrid {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
}

.detailsGrid.columns2 {
  grid-template-columns: 1fr 2fr;
}

.detailsGrid .detailLabel {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-decoration: underline;
}

.detailsGrid .detailValue {
  font-size: 20px;
  font-weight: 500;
}

.detailsGrid .gridCellLink {
  font-size: 16px; /* @todo Remove after updating Button component */
  margin-top: 24px;
}

.fixedActions {
  background-color: rgb(248, 248, 248);
  box-shadow: 0 -4px 4px 0 var(--foremost-black-faint-3);
  padding: 25px 20px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;

  position: fixed;
  bottom: 0;
  left: 0;
}

.heading1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.heading2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1em;
}

.headingName {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 1em;
}

.listTable {
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  width: 100%;
}

.listTable .buttonPanel {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  align-items: center;
}

.listTable .multiItemCell {
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.listTable .multiItemVertCell {
  line-height: 1.7;
}

.listTable td,
.listTable th {
  border-bottom: 1px solid #acb3cb;
  padding: 20px 0;
  cursor: default;
}

.listTable td:not(:last-child),
.listTable th:not(:last-child) {
  padding-right: 15px;
}

.listTable th {
  font-size: 14px;
  font-weight: 600;
}

/* Altering styling of FullCalendar's intrinsic elements.
When applying "customDateHeader" class above FullCalendar instance,
this expands the date header width fully to enable adding elements. */
.customDateHeader .fc .fc-daygrid-day-number {
  width: 100%;
}

.noEventHover .fc-daygrid-dot-event:hover {
  background: unset;
}

.smallHeading .fc .fc-toolbar-title {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .detailsGrid {
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
  }

  .largeScreenOnly {
    display: none;
  }
}
